
@media screen and (min-width: 100px) and (max-width: 768px) {
    /* html, body {
        height: 100%;
        width: 100%;
        overflow: hidden;
    } */
    .container {
        display: none;
     }
     .mobile p{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 250px;
        left: 0;
        right: 0;
        text-align: center;
        color: white;
     }
    
}

@media screen and (min-width: 769px) and (max-width: 4000px) {
    /* html, body {
        height: 100%;
        width: 100%;
        overflow: hidden;
    } */
    .container {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 0;
     }
    
     .internal {
        width: 100%;
        height: 100%;
    }
    .mobile{
        display: none;
    }
    
}