


/* /*d4d4d4 */
@media screen and (min-width: 1025px){

  .team-card-individual {
    display: block;
    flex: 1;
    font-weight: bold;
    font-size: 11px;
    color: black;
    line-height: 100%;
    text-align: left;
  }
  .team-card-individual h3 {
    font-size: 14px;
    font-weight: bolder;
    padding-top: 30px;
  }
  .team-card-individual p {
    font-weight: normal;
    padding-bottom: 5px;
  }
  
  .team-card-photo {
    border-radius: 50%;
    height: 192px;
    width: 192px;

  }
  
  .linkden {
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    position: relative;
    top: 180px;
    left: 60px;
  }
  
  .team-card-container:hover * {
    background: #d4d4d4;
  
  }


}

@media screen and (min-width: 100px) and (max-width:1024px) {

  .team-inner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .team-flex-container{
    width: 300px;
  }
  .team-card-individual {
  display: block;
  flex: 1;
  font-weight: bold;
  font-size: 11px;
  color: black;
  line-height: 100%;
  text-align: center;
}
.team-card-individual h3 {
  font-size: 14px;
  font-weight: bolder;
  padding-top: 30px;
}
.team-card-individual p {
  font-weight: normal;
  padding-bottom: 5px;
}

.team-card-photo {
  border-radius: 50%;
  height: 192px;
  width: 192px;
}

.linkden {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: 70px;
  top: 180px;
}

.team-card-container:hover * {
  background: #d4d4d4;

}

}